import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import {toggleLang, getLang} from '../services/lang';
import Layout from '../components/Layout';
import ParallaxBanner from '../components/Slices/ParallaxBanner';
import ParallaxContent from '../components/Slices/ParallaxContent';


class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }


    this.toggleLanguage = this.toggleLanguage.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
  }

  componentDidUpdate() {
    const currLang2 = getLang();
    console.log('compUpdState: ', currLang2)
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
      }
    console.log('currLangToggle: ', lang)
    console.log('Toggle to: ', this.state.lang)
  }

  render() {
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <ParallaxBanner lang={this.state.lang} />
        <ParallaxContent />
      </Layout>
    );
  }

}

export default IndexPage;
