import React from 'react';

import './ParallaxBanner.css';
import Logo from '../../images/Logo_Quinta_lili.png';
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';
import {getLang} from '../../services/lang';
import * as TXT from '../../res/strings';
import * as TXTEN from '../../res/strings_en';
import WAPP_BTN from '../../images/whatsapp-button.png';


class ParallaxBanner extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      openPop: false,
      windowWidth: 0,
      windowHeight: 0,
      lang: 'ES',
    }

    this.togglePop = this.togglePop.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    this.updateWindowDimensions();
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
    }
    window.addEventListener('resize', this.updateWindowDimensions);
    setTimeout( 
      () => { this.togglePop(); }, 3000
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  togglePop() {
    this.setState({
      openPop: !this.state.openPop
    });
}

  render() {
    const ANUNCIO_LARGE = 'http://quintaliliteques.com/promociones/anuncio-large.jpg';
    const ANUNCIO_SMALL = 'http://quintaliliteques.com/promociones/anuncio-small.jpg';
    const smallWappBTN = <img src={WAPP_BTN} style={{ width: '300px', height: 'auto' }}></img>
    const largeWappBTN = <img src={WAPP_BTN}></img>
    const APP_LANG = this.state.lang;
    const WAPP_MSG_EN = "https://wa.me/5215554064759?text=I'm%20interested%20in%20QuintaLili's%20reservation,%20please%20tell%20me%20more";
    const WAPP_MSG_ES = "https://wa.me/5215554064759?text=Estoy%20interesad@%20en%20reservar%20QuintaLili,%20por%20favor%20mas%20informacion";
    const largeImage = <ModalBody>
        <img src={ANUNCIO_LARGE} alt="Quinta Lili Promotion Image" style={{ paddingBottom: 20, }} />
      </ModalBody> ;

    const shortImage = <ModalBody>
        <img src={ANUNCIO_SMALL} alt="Quinta Lili Promotion Image" style={{ width: '100%', maxWidth: '760px', height: '100%', maxHeight: '690px', paddingBottom: 20, }} />
      </ModalBody>;

    const largeLogo = <img src={Logo} height="300px" width="auto" alt="Logo Quinta Lili"/> ;
    const shortLogo = <img src={Logo} height="150px" width="auto" alt="Logo Quinta Lili"/> ;

    return(
      <div>
        <Modal contentClassName="popUpArea" isOpen={this.state.openPop} toggle={this.togglePop}>
          <ModalHeader toggle={this.togglePop}>Nuestra Promoción del mes</ModalHeader>
          { this.state.windowWidth >= 1023 ? largeImage : shortImage }
        </Modal>
        <div className="prlxDiv">
          <div id="overlay">
            <div className="heroTitles">
              { this.state.windowWidth >= 1023 ? largeLogo : shortLogo }
              <h1>
                <strong>
                  QUINTA LILI
                </strong>
              </h1>
              <div className="container">
                <h2>
                  Tequesquitengo, Morelos, Mexico.
                </h2>
              </div>
              <div id="arrowAnim">
                <div className="arrowSliding">
                  <div className="arrow"></div>
                </div>
                <div className="arrowSliding delay1">
                  <div className="arrow"></div>
                </div>
                <div className="arrowSliding delay2">
                  <div className="arrow"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <Container>
            <Row>
              <Col className="text-center title">
                <h1>
                  {APP_LANG === 'EN' ? TXTEN.RESERVE.TITLE1 : TXT.RESERVE.TITLE1}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
              <p>{APP_LANG === 'EN' ? TXTEN.CONTACT.WHATSAPP_TITLE : TXT.CONTACT.WHATSAPP_TITLE}</p>
              <a href={APP_LANG === 'EN' ? WAPP_MSG_EN : WAPP_MSG_ES} target="_blank" rel="noopener noreferrer">
                { this.state.windowWidth >= 1023 ? largeWappBTN : smallWappBTN } </a>
              </Col>
            </Row>
            <br />
        </Container>
      </div>
    );
  }
}

export default ParallaxBanner;
