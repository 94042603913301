import React from 'react';
import {Link} from 'gatsby';
import './ParallaxContent.css';

import * as LNK from '../../res/links';
import * as TXT from '../../res/strings';
import * as TXTEN from '../../res/strings_en';
import {getLang} from '../../services/lang';


class ParallaxContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
  }

  render() {
    const APP_LANG = getLang().language;
    return (
      <div>
        <div className="prlxCntDiv">
          <div id="overlayCnt">
            <div className="heroTitles">
              <h1>
                <strong>
                  {APP_LANG === 'EN' ? TXTEN.RESERVE.TITLE1 : TXT.RESERVE.TITLE1}
                </strong>
              </h1>
              <div className="container">
                <Link to={LNK.MENU3}>
                  <div className="btnActionMain mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.CTA_RESERVE : TXT.BUTTON.CTA_RESERVE}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="prlxCntDiv">
          <div id="overlayCnt">
            <div className="heroTitles">
              <h1>
                <strong>
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.TITLE1 : TXT.ROOMS.TITLE1}
                </strong>
              </h1>
              <div className="container">
              <Link to={LNK.MENU2}>
                <div className="btnActionMain mt-auto">
                  {APP_LANG === 'EN' ? TXTEN.BUTTON.VIEW : TXT.BUTTON.VIEW}
                </div>
              </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="prlxCntDiv">
          <div id="overlayCnt">
            <div className="heroTitles">
              <h1>
                <strong>
                  {APP_LANG === 'EN' ? TXTEN.FACILITIES.TITLE1 : TXT.FACILITIES.TITLE1}
                </strong>
              </h1>
              <div className="container">
              <Link to={LNK.MENU4}>
                <div className="btnActionMain mt-auto">
                  {APP_LANG === 'EN' ? TXTEN.BUTTON.VIEW : TXT.BUTTON.VIEW}
                </div>
              </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="prlxCntDiv">
          <div id="overlayCnt">
            <div className="heroTitles">
              <h1>
                <strong>
                  {APP_LANG === 'EN' ? TXTEN.SERVICES.TITLE1 : TXT.SERVICES.TITLE1}
                </strong>
              </h1>
              <div className="container">
              <Link to={LNK.MENU5}>
                <div className="btnActionMain mt-auto">
                  {APP_LANG === 'EN' ? TXTEN.BUTTON.VIEW : TXT.BUTTON.VIEW}
                </div>
              </Link>
              </div>
            </div>
          </div>
        </div>
        
        
      </div>
    );
  }
}

export default ParallaxContent;
